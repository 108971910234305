.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}
/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}
*/

.portfolio-logo {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  height: 10vmin;
}

.logo-bar {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.App-header {
  background-color: #00193F;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 4vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  display: flex;
  align-items: flex-end;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
